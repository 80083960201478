<template>
  <div class="qrcode_holder">
    <!-- <video-player :options="editVideoOptions" playIn="mobile" ref="refVideoPlayer" /> -->
    <!-- <video-player
      ref="refVideoPlayer"
      :options="editVideoOptions"
      playIn="mobile"
      :isShareMode="true"
      @singleTap="singleTap"
      @doubleTap="doubleTap"
    /> -->
    <div class="video_box">
      <video
        class="video"
        :src="videoSrc"
        controls
        :poster="videoPoster"
      ></video>
    </div>
  </div>
</template>
<script>
import VideoPlayer from "@/components/videoPlayer/VideoPlayer";
import axios from "axios";
// import VConsole from "vconsole";
export default {
  data: function () {
    return {
      editVideoOptions: {},
      iShareDesc: "",
      iShareImage: "",
      sShareTitle: "",

      videoSrc: "",
      videoPoster: "",
    };
  },
  components: {
    VideoPlayer,
  },
  created() {},
  methods: {
    async initAgentConfig() {
      const _that = this;
      const params = {
        url: encodeURIComponent(location.href.split("#")[0]),
      };
      const { data } = await axios.post(
        "https://dtying.com/master_material/wechat/getSignature",
        params
      );
      wx.config({
        // debug: true, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.data.appId, // 必填，公众号的唯一标识
        timestamp: data.data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.data.nonceStr, // 必填，生成签名的随机串
        signature: data.data.signature, // 必填，签名
        jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"], // 必填，需要使用的JS接口列表
      });
      wx.ready(function () {
        wx.updateAppMessageShareData({
          title: _that.sShareTitle, // 分享标题
          desc: _that.iShareDesc, // 分享描述
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.iShareImage, // 分享图标
          success: function () {
            console.log("调用微信分享接口成功！");
          },
          fail: function () {
            console.log("调用微信分享接口失败！");
          },
        });
        wx.updateTimelineShareData({
          title: _that.sShareTitle, // 分享标题
          link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: _that.iShareImage, // 分享图标
          success: function () {
            console.log("调用微信朋友圈分享接口成功！");
          },
          fail: function () {
            console.log("调用微信朋友圈分享接口失败！");
          },
        });
      });
      wx.error(function (res) {});
    },
    singleTap() {
      this.$refs.refVideoPlayer.togglePlayer();
    },
    doubleTap() {
      this.$refs.refVideoPlayer.moniterDoubleClick();
    },
    getVideoDetail(videoId) {
      const _that = this;
      return new Promise(async (resolve) => {
        const params = JSON.stringify({
          sVideoId: videoId,
        });
        const { data } = await _that.API.appSlider.getShareVideoInfo(params);
        _that.videoSrc = data.stVideoInfo.sVideoUrl;
        _that.videoPoster = data.stVideoInfo.sPicUrl;
        // if (data.vInteractiveVideo.length == 0) {
        //   return resolve();
        // }

        // _that.editVideoOptions = JSON.parse(data.vInteractiveVideo[0].sUpdateContent);
        // _that.editVideoOptions.height = document.documentElement.clientHeight;
        // _that.iShareDesc = data.vInteractiveVideo[0].iShareDesc || _that.editVideoOptions.video.sDes || ''
        // _that.iShareImage = data.vInteractiveVideo[0].iShareImage || _that.editVideoOptions.video.smallPicUrl || ''
        // _that.sShareTitle = data.vInteractiveVideo[0].sShareTitle || _that.editVideoOptions.video.sTitle || ''
        resolve();
      });
    },
  },
  async mounted() {
    // var vConsole = new VConsole();
    await this.getVideoDetail(this.$route.query.videoId);
    // this.initAgentConfig();
  },
};
</script>
<style lang="less" scoped>
.qrcode_holder {
  height: 100%;
  background: black;
  position: relative;
  .video_box {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    .video {
      width: 100%;
    }
  }
}
</style>
